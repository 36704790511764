export default defineNuxtRouteMiddleware(async (to, from) => {
  if (!Object.values(provincialCities).includes(to.params.cityUri)) {
    return navigateTo('/not-found');
  }
  const { page = 1 } = to.query;
  const params = {
    page: page as number,
    city_uri_id: to.params.cityUri as string,
    seo_service_title_uri_id: to.params.problemUri as string,
    size: 1,
    image_size: '840x840',
  };
  const data = await getServicePoints(params);
});
